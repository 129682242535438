/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Input, Label, Modal, Button, ModalHeader, ModalFooter, ModalBody, FormGroup, Form, Spinner } from "reactstrap";
import AsyncSelect from "react-select/async";
import { deviceEmailsAPI } from 'services/Device';
import { sendNotificationOnEmails } from 'services/Device';
import * as XLSX from 'xlsx';

const NotifyByEmailModel = ({ notificationByEmailModel, setNotificationByEmailModel }) => {
    const [title, setTitle] = useState('Telepathy Lamp');
    const [msg, setMsg] = useState('Welcome to Telepthy lamp');
    const [imageUrl, setImageUrl] = useState("");
    const [emails, setEmails] = useState("");
    const [redirectUrl, setRedirectUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => {
        setEmails([]);
        setMsg("Welcome to Telepthy lamp")
        setTitle("Telepathy Lamp")
        setImageUrl("")
        setRedirectUrl("")
        setNotificationByEmailModel(false)
    }
    const fetchEmails = async (inputValue = "") => {
        try {
            const response = await deviceEmailsAPI(inputValue);
            if (response.result?.type === "success") {
                const emailOptions = response.result.emails.map((emailObj) => ({
                    value: emailObj.email,
                    label: emailObj.email,
                    tokens: emailObj.tokens || [],
                }));

                return emailOptions;
            } else {
                console.error("Error: Unexpected response type", response.result?.type);
                return [];
            }
        } catch (error) {
            console.error("Error fetching emails:", error);
            return [];
        }
    };

    const handleEmailChange = (selectedOptions) => {
        setEmails(selectedOptions);
    };
    const handleOnSendPushNotification = async () => {
        setIsLoading(true);
        try {
            const emailAddresses = emails.map((email) => email.value);
            const mappedTokens = emails.flatMap((email) => email.tokens || []);

            const body = {
                email: emailAddresses,
                token: mappedTokens,
                title: title,
                message: msg,
                imageURL: imageUrl,
                redirectURL: redirectUrl,
            };
            await sendNotificationOnEmails(body)
            handleClose();
        } catch (error) {
            console.error("Error in handleOnSendPushNotification:", error);
        } finally {
            setIsLoading(false); // Stop the loader
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const fileExtension = file.name.split(".").pop().toLowerCase();
    
        try {
            let extractedEmails = [];
    
            if (fileExtension === "xlsx" || fileExtension === "xls") {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    
                // Extract emails from the rows
                extractedEmails = rows.flat().filter((cell) => validateEmail(cell));
            } else {
                console.error("Unsupported file type");
                return;
            }
    
            for (const email of extractedEmails) {
                try {
                    const response = await deviceEmailsAPI(email);
                    if (response.result?.type === "success") {
                        const emailOptions = response.result.emails.map((emailObj) => ({
                            value: emailObj.email,
                            label: emailObj.email,
                            tokens: emailObj.tokens || [],
                        }));
    
                        setEmails((prev) => {
                            const updatedEmails = [...prev, ...emailOptions];
                            const uniqueEmails = updatedEmails.filter(
                                (item, index, self) =>
                                    index === self.findIndex((email) => email.value === item.value)
                            );
                            return uniqueEmails;
                        });
                    } else {
                        console.error(`Error fetching tokens for email: ${email}`);
                    }
                } catch (error) {
                    console.error(`Error processing email: ${email}`, error);
                }
            }
        } catch (error) {
            console.error("Error processing file:", error);
        }
    };
    

    const validateEmail = (email) =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    return (<>
        <Modal
            isOpen={notificationByEmailModel} toggle={handleClose}>
            <ModalHeader >
                <a style={{ fontSize: 'large' }}>Send Notification to Email</a>
            </ModalHeader>
            <ModalBody style={{ maxHeight: '600px', overflowY: 'auto' }}>
                <Form >
                    <FormGroup >
                        <Label className="floating-label" htmlFor="emails" style={{ color: "#000" }}>Emails</Label>
                        <AsyncSelect
                            isMulti
                            name="emails"
                            cacheOptions
                            loadOptions={fetchEmails}
                            value={emails}
                            onChange={handleEmailChange}
                            placeholder="Search and select emails"
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label className="floating-label" htmlFor="fileUpload" style={{ color: "#000" }}>
                            Upload File (Excel)
                        </Label>
                        <Input
                            type="file"
                            id="fileUpload"
                            onChange={handleFileUpload}
                            accept=".xlsx, .xls"
                        />
                    </FormGroup>
                    <FormGroup >
                        <Label className="floating-label" htmlFor="title" style={{ color: "#000" }}>Title</Label>
                        <Input
                            // value={title}
                            onChange={(e) => { setTitle(e.target.value) }}
                            type="text"
                            name="title"
                            className="form-control-alternative"
                            id="categoryCode"
                            defaultValue={title}
                            placeholder="Enter Title"
                            required="" />
                    </FormGroup>
                    <FormGroup >
                        <Label className="floating-label" htmlFor="message" style={{ color: "#000" }}>Message</Label>
                        <Input
                            // value={msg}
                            defaultValue={msg}
                            onChange={(e) => { setMsg(e.target.value) }}
                            type="textarea"
                            name="message"
                            rows="5"
                            className="form-control-alternative"
                            id="message"
                            placeholder="Enter Message"
                        />
                    </FormGroup>
                    <FormGroup >
                        <Label className="floating-label" htmlFor="message" style={{ color: "#000" }}>Image URL</Label>
                        <Input
                            // value={msg}
                            defaultValue={imageUrl}
                            onChange={(e) => { setImageUrl(e.target.value) }}
                            type="text"
                            name="Image URL"
                            rows="5"
                            className="form-control-alternative"
                            id="imageUrl"
                            placeholder="Image URL"
                        />
                    </FormGroup>
                    <FormGroup >
                        <Label className="floating-label" htmlFor="message" style={{ color: "#000" }}>Redirect URL</Label>
                        <Input
                            // value={msg}
                            defaultValue={redirectUrl}
                            onChange={(e) => { setRedirectUrl(e.target.value) }}
                            type="text"
                            name="Redirect URL"
                            rows="5"
                            className="form-control-alternative"
                            id="redirectUrl"
                            placeholder="Image URL"
                        />
                    </FormGroup>

                </Form>
            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    style={{ backgroundColor: "#CFD2D8" }}
                    variant="primary"
                    onClick={handleOnSendPushNotification}
                    disabled={isLoading} // Disable button while loading
                >
                    {isLoading ? (
                            <Spinner size="sm" className="me-2" />
                    ) : (
                        "Send"
                    )}
                </Button>
            </ModalFooter>
        </Modal>
    </>)
}
export default NotifyByEmailModel;