import { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Form, FormGroup, Label, CustomInput, Input } from "reactstrap";
import { getImageData, uploadVideo } from '../../services/Device';

const UploadVideoModal = ({ uploadVideoModal, setUploadVideoModal, toggle, refreshData }) => {
    const [title, setTitle] = useState("");
    const [thumbnailData, setThumbnailData] = useState({});
    const [youtubeUrl, setYoutubeUrl] = useState("");  // State for YouTube URL
    const [errorDisplay, setErrorDisplay] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleClose = () => {
        setUploadVideoModal(false);
    };

    const onSubmit = async () => {
        console.log("Title:", title);
        console.log("YouTube URL:", youtubeUrl); // Log the YouTube URL

        if (thumbnailData) {
            const formImageData = new FormData();
            formImageData.append('image', thumbnailData);
            console.log("Form Data for Thumbnail Upload:", formImageData);

            const responseImage = await getImageData(formImageData);
            console.log("Get Image Data Response:", responseImage);

            if (responseImage.result.type === "error") {
                setErrorMessage(`(${responseImage.result.message})`);
                setErrorDisplay(true);
            } else {
                const apiBody = {
                    title: title,
                    youtubeUrl: youtubeUrl,  // Add the YouTube URL to the request body
                    thumbnail: responseImage.result.data.filename
                };

                const responseUploadVideo = await uploadVideo(apiBody);
                console.log("Final Upload Video Response:", responseUploadVideo);

                if (responseUploadVideo.result.type === "success") {
                    setTitle("");
                    setYoutubeUrl(""); // Reset YouTube URL
                    setThumbnailData({});
                    setUploadVideoModal(false);
                    refreshData();
                } else {
                    setErrorMessage(`(${responseUploadVideo.result.message})`);
                    setErrorDisplay(true);
                }
            }
        } else {
            setErrorMessage("Please choose a thumbnail.");
            setErrorDisplay(true);
        }
    };

    return (
        <>
            <Modal isOpen={uploadVideoModal} toggle={toggle}>
                <ModalHeader>
                    <span style={{ fontSize: 'large' }}>Upload Video</span>
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label htmlFor="title" style={{ color: "#000" }}>Title <span style={{ color: "red", fontSize: "small" }}>*</span></Label>
                            <Input
                                onChange={(e) => setTitle(e.target.value)}
                                type="text"
                                name="title"
                                id="title"
                                value={title}
                                placeholder="Enter Title"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="youtubeUrl" style={{ color: "#000" }}>YouTube URL</Label>
                            <Input
                                onChange={(e) => setYoutubeUrl(e.target.value)}
                                type="text"
                                name="youtubeUrl"
                                id="youtubeUrl"
                                value={youtubeUrl}
                                placeholder="Enter YouTube URL (optional)"
                            />
                            <small>(Optional: Add a valid YouTube URL)</small>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="thumbnail" style={{ color: "#000" }}>Choose Thumbnail <span style={{ color: "red", fontSize: "small" }}>*</span></Label>
                            <CustomInput
                                type="file"
                                name="uploaded-thumbnail"
                                id="thumbnail"
                                label="Add Thumbnail..."
                                onChange={(e) => setThumbnailData(e.target.files[0])}
                                required
                            />
                            <small>(File extension should be .jpg, .jpeg, or .png)</small>
                        </FormGroup>
                        {errorDisplay && <div style={{ fontSize: 'small', color: "red" }}>{errorMessage}</div>}
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button style={{ backgroundColor: "#CFD2D8" }} color="primary" onClick={onSubmit}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default UploadVideoModal;
