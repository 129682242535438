

import React, { useState,useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label } from 'reactstrap';
import {apiScheduleGet } from '../../services/Consts';


const ScheduleModal = ({ isOpen, toggle, saveSchedule }) => {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
const [screenname, setscreenname] = useState('');
const[Scheduletime,setScheduletime]=useState('');
  const handleChange = (e) => {
    setScheduletime(e.target.value); 
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  const handlescreenChange = (e) => {
    setscreenname(e.target.value);
  };
  const handleSave = () => {
    saveSchedule(Scheduletime, title, message,screenname); // Pass title and message to the parent function
  };
 const getCurrentSchedule = async () => {
    try {
      const schedule = await fetchSchedule();
      if (schedule) {
        setScheduletime(schedule.Scheduletime || "Enter schedule time in minutes");
        setTitle(schedule.title || "Enter Title");
        setMessage(schedule.message || "Enter Message");
        setscreenname(schedule.screenname || "Enter Screen");
      }
    } catch (error) {
      console.error('Error fetching current schedule:', error);
    }
  };
  const fetchSchedule = async () => {
    try {
      const response = await fetch(apiScheduleGet);
      const data = await response.json();
      console.log('API Response:', data); // Log the API response
      return data; // Return the data
    } catch (error) {
      console.error('Error fetching schedule:', error);
      return null;
    }
  };
  useEffect(() => {
    getCurrentSchedule();
 }, []);  

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Set Notification Schedule(In Minutes)</ModalHeader>

      <ModalBody>
        <Label className="floating-label" htmlFor="scheduleTime" style={{ color: "#000" }}>
          Enter schedule time in minutes
        </Label>
        <Input
          type="number"
          value={Scheduletime}
          onChange={handleChange}
          placeholder="Enter schedule time in minutes"
        />
        
        <Label className="floating-label" htmlFor="title" style={{ color: "#000" }}>
          Enter Title
        </Label>
        <Input
          type="text"
          value={title}
          onChange={handleTitleChange}
          placeholder="Enter title for notification"
        />
        
        <Label className="floating-label" htmlFor="message" style={{ color: "#000" }}>
          Enter Message
        </Label>
        <Input
          type="text"
          value={message}
          onChange={handleMessageChange}
          placeholder="Enter message for notification"
        />
         <Label className="floating-label" htmlFor="message" style={{ color: "#000" }}>
          Enter Screen
        </Label>
        <Input
          type="text"
          value={screenname}
          onChange={handlescreenChange}
          placeholder="Enter message for notification"
        />
      </ModalBody>

      <ModalFooter>
        <Button variant="secondary" onClick={toggle}>Cancel</Button>
        <Button style={{ backgroundColor: "#CFD2D8" }} onClick={handleSave}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ScheduleModal;
