import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardHeader, Container, Row } from "reactstrap";
import Header from "components/Headers/Header.js";
import { deviceNotificationAPI } from '../services/Device';
import NotifyModal from '../components/Popup/NotifyModal';
import TableContainer from 'components/TableContainer';
import NotifyByEmailModel from 'components/Popup/notifyByEmailModal';
import ScheduleModal from "../components/Popup/NotificationDuration";
import { apiScheduleUpdate,apiScheduleGet } from '../services/Consts';
const Notifications = () => {
  const [notificationModel, setNotificationModel] = useState(false);
  const [notificationByEmailModel, setNotificationByEmailModel] = useState(false);
  const [notificationDetails, setNotificationDetails] = useState(false);
  const [deviceNotificationData, setDeviceNotificationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
const [scheduleTime, setScheduleTime] = useState(""); // Store the new schedule time

const toggleScheduleModal = () => setScheduleModal(!scheduleModal);


const getCurrentSchedule = async () => {
  try {
    const schedule = await fetchSchedule();
    if (schedule) {
      setScheduleTime(schedule.time || "");
    }
  } catch (error) {
    console.error('Error fetching current schedule:', error);
  }
};
const saveSchedule = async (scheduleTime, title, message,screenname) => {
  let scheduleInMinutes = parseFloat(scheduleTime);

  if (isNaN(scheduleInMinutes) || scheduleInMinutes <= 0) {
    alert('Please enter a valid positive number for the schedule time.');
    return;
  }

  try {
    const response = await updateSchedule(scheduleTime, title, message,screenname); // Pass title and message to updateSchedule
    if (response.message === 'Schedule updated successfully') {
      toggleScheduleModal();
      refreshlist();
    } else {
      console.error("Failed to save schedule.");
    }
  } catch (error) {
    console.console.log();
    ('Error saving schedule:', error);
  }
};


useEffect(() => {
  getCurrentSchedule();
  getAllDeviceNotification();
}, []);
const fetchSchedule = async () => {
  try {
    const response =  await fetch(apiScheduleGet)
        return await response.json();
  } catch (error) {
    console.error('Error fetching schedule:', error);
    return null;
  }
};



const updateSchedule = async (scheduleTime, title, message,screenname) => {
  try {
    const response = await fetch(apiScheduleUpdate, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ scheduleTime, title, message, screenname }),
    });


    if (!response.ok) {
      throw new Error('Failed to update schedule');
    }

    const result = await response.json();
    console.log('Schedule updated successfully:', result);
    return result;
  } catch (error) {
    console.error('Error updating schedule:', error);
    return { success: false };
  }
};

  const toggle = () => setNotificationModel(!notificationModel);

  const refreshlist = () => {
    setLoading(true);
    document.getElementById("refreshbtn").className = "fas fa-sync fa-spin";
    getdata();
    if (loading) { setLoading(false) }
  }

  const getdata = () => {
    setLoading(true);
    getAllDeviceNotification();
  }

  useEffect(() => {
    getdata()
  }, [])

  const getAllDeviceNotification = () => {
    deviceNotificationAPI().then((data) => {
      if (data.result.type === "success") {
        setDeviceNotificationData(data.result.users);
        setLoading(false);
        document.getElementById("refreshbtn").className = "fas fa-sync fa-stop";
      } else {
        setLoading(true);
      }
    }).catch(error => {
      console.log("Error fetching notifications:", error);
      setLoading(false);
    });
  };

  const showShortLocation = (value) => {
    value = "" + value;
    if (value.length > 25) {
      value = value.substr(0, 31) + "...";
    } else {
      value;
    }
    return value;
  }

  const handleShow = (item) => {
    setNotificationDetails(item);
    setNotificationModel(true);
  }

  
  


  const handleModalShow = () => {
    setNotificationByEmailModel(true)
  }
  const columns = useMemo(
    () => [
      {
        Header: "NO",
        accessor: "id",
        Cell: (cellProps) => (
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 ms-2 name text-center m-1">
              {cellProps.row.index + 1}
            </div>
          </div>
        ),
      },
      {
        Header: "TOKEN",
        accessor: "token",
        Cell: (cellProps) => (
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 ms-2 name text-center m-1">
              {showShortLocation(cellProps.row.original.token)}
            </div>
          </div>
        ),
      },
      {
        Header: "EMAIL",
        accessor: "email",
        Cell: (cellProps) => (
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 ms-2 name text-center m-1">
              {showShortLocation(cellProps.row.original.email)}
            </div>
          </div>
        ),
      },
      {
        Header: "PLATFORM",
        accessor: "platform",
        Cell: (cellProps) => (
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 ms-2 name text-center m-1 ">
              {cellProps.row.original.platform}
            </div>
          </div>
        ),
      },
      {
        Header: "APP VERSION",
        accessor: "appVersion",
        Cell: (cellProps) => (
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 ms-2 name text-center m-1 ">
              {cellProps.row.original.appVersion}
            </div>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div >
    
      <ScheduleModal
  isOpen={scheduleModal}
  toggle={toggleScheduleModal}
  scheduleTime={scheduleTime}
  setScheduleTime={setScheduleTime}
  saveSchedule={saveSchedule}
/>

        <NotifyByEmailModel notificationByEmailModel={notificationByEmailModel} setNotificationByEmailModel={setNotificationByEmailModel} />
        <NotifyModal notificationModel={notificationModel} setNotificationModel={setNotificationModel} toggle={toggle} notifyDetails={notificationDetails} />
        <Header />
        <Container className="mt--7" fluid style={{ marginTop: '-8rem !important' }}>
          <Row>
            <div className="col">
              <Card className="shadow" style={{ height: '82vh' }}>
                <CardHeader className="border-0">
                  <h3 className="mb-2">Notifications</h3>
                  <div style={{ cursor: 'pointer', float: 'right', marginTop: 5 }}>
                  <i onClick={() => { setScheduleModal(true) }} className="fa fa-cog pr-3" />
                  <i onClick={() => { handleModalShow() }} className="fa fa-envelope pr-3" />
                    <i onClick={() => { handleShow() }} className="fa fa-paper-plane pr-3" />
                    <i id="refreshbtn" onClick={() => { refreshlist() }} className="fas fa-sync" />

                  </div>
                </CardHeader>
                <TableContainer
                  columns={columns}
                  data={deviceNotificationData || []}
                  customPageSize={10}
                  className="custom-header-css"
                />
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Notifications;
