import moment from 'moment';
// export var apiEndPoint = 'https://api-fempulse.kesemsolutions.com/';
// export var apiUrl = 'http://localhost:9001/api/';
// export let apiUrl = "http://192.168.1.6:9001/api/";
export let apiUrl = "https://api-telepathy.pyther.com/api/"
export let videoUrl = "https://api-telepathy.pyther.com/videos/"
// export let apiUrl = "http://localhost:9001/api/";
// export let videoUrl = "http://localhost:9001/videos/";
// api-telepathy.pyther.com

export var apiLoginUser = apiUrl + 'auth/login';
export var apiDeviceData = apiUrl + 'deviceDetail';
export var apiDeviceSession = apiUrl + 'deviceSession/';
export var apiVideoData = apiUrl + "video";
export var apiNotification = apiUrl + 'notificationDetails';
export var firebaseApi = "https://fcm.googleapis.com/fcm/send";
export var firebaseKeyApi = "https://fcm.googleapis.com/fcm/notification";
export var apiScheduleGet = apiUrl + 'notificationduration/getSchedule';
export var apiScheduleUpdate = apiUrl + 'notificationduration/updateSchedule';


export const getDateFormat = (data) => moment(data).format('DD/MM/YYYY HH:MM:ss');
